import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout
    headerImage={(
      <div style={{ backgroundColor: "#4B7E89" }}>
        <div class="pt-4 pb-4">
          <h1 class="h1-text text-center mb-0">Contact Me</h1>
        </div>
      </div>
    )}
  >
    <SEO title="Contact" />
    <form 
      method="POST" 
      action="https://formspree.io/f/meqpbylz" 
      onSubmit={() => console.log('Submitted!')}
      style={{ margin: "1rem auto", maxWidth: "768px" }}
    >
      <div class='row mt-5'>
        <div class="col">
          <h2>Let's Connect!</h2>
          <p>I'm always looking for new ways to get more involved in the development community and connecting with new people!</p>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" class="form-control" />
          </div>
          <div class="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" name="_replyto" id="email" class="form-control" />
          </div>
          <div class="form-group">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="5" class="form-control" />
          </div>
          <button type="submit" class="btn btn-primary">Send</button>
        </div>
      </div>
    </form>
  </Layout>
)

export default ContactPage
